<template>
  <div>
    <div class="diyDesignContainer">
      <div class="backView">
        <a-button
          class="backBtn"
          style="color: black !important;background-color: white !important;"
          type="text"
          @click="callNewPage(`/product/${blankProductInfo.type}/${productId}`)"
        >Back</a-button>
      </div>
      <div class="designContent">
        <div class="designLeftContent">
          <div class="customEffectsView">
            <div class="name font20 ff-ss fc-333 fw-400">Generated Mockup Effect</div>
            <div class="description ff-ss fc-333 fw-400 fs-14">
              Your uploaded pictures & texts content will be produced and shown as the followed effect:
            </div>
            <div
              :class="showMoreImage ? `imgView h-auto` : `imgView`"
              style="overflow-y: hidden"
            >
              <img
                v-for="(item, index) in showImageList"
                :key="'image' + index"
                class="imgItem"
                :src="item"
              >
            </div>
            <!-- 点击展示全部/部分start -->
            <div v-if="!showMoreImage" class="getMore" @click="showGetMore">
              <a-icon type="down" />
            </div>
            <div v-if="showMoreImage" class="getMore" @click="showGetMore">
              <a-icon type="up" />
            </div>
            <!-- 点击展示全部/部分end -->
          </div>
        </div>
        <a-form :form="form" :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }">
          <div class="designRightContent">
            <!--金额信息-->
            <div class="designRightTitleView mb15">
              <div class="name font20 fc-333 ff-ss ">
                {{ blankProductInfo.model }}
              </div>
              <div class="price ff-ss font20">${{ price * quantity | MoneyFormat }}</div>
            </div>
            <!-- <div class="designRightRemarkView ff-ss fs-14 fw-400">
              Additional charge include: gift card cost\wrap package cost
            </div> -->
            <!--金额信息-->
            <!--图片框信息-->
            <div v-if="blankProductImageBoxInfos && !!blankProductImageBoxInfos.length" class="customEffectsItemView">
              <div class="font20 fc-333 ff-ss" style="font-weight: bold">
                Add Your Image
              </div>
              <diy-image-box
                v-for="item in blankProductImageBoxInfos"
                :key="item.id"
                :image-url.sync="item.imageUrl"
                :is-required="item.isRequired"
                :psd-file-url="item.psdFileUrl"
                :remark="item.remark"
                :title="item.enName"
              />
            </div>
            <!--文字框信息-->
            <div v-if="blankProductInputBoxInfos && !!blankProductInputBoxInfos.length" class="typeYourTextView">
              <div class="font20 fc-333 ff-ss" style="font-weight: bold">
                Add Your Text
              </div>
              <diy-input-box
                v-for="item in blankProductInputBoxInfos"
                :key="item.id"
                :font-family="item.fontFamily"
                :text.sync="item.text"
                :is-required="item.isRequired"
                :title="item.enName"
                :remark="item.remark"
                :word-num-limit="item.wordNumLimit"
              />
            </div>

            <sku-selector
              ref="sku-selector"
              label-prefix=""
              place-holder-prefix="Please select a"
              :skus="skuInfos"
              :option1-name="blankProductInfo.skuOption1"
              :option1-type="blankProductInfo.skuOption1Type"
              :option2-name="blankProductInfo.skuOption2"
              :option2-type="blankProductInfo.skuOption2Type"
              :option3-name="blankProductInfo.skuOption3"
              :option3-type="blankProductInfo.skuOption3Type"
              @skuChange="onSkuChange"
            />
            <div class="noteToSellerView">
              <div class="font20 fc-333 ff-ss" style="font-weight: bold">
                Add a note
              </div>
              <div class="sellerMessageView">
                <div class="itemName fs-16 fc-333 ff-ss fw-400">
                  Content
                </div>
                <div class="itemInputView d-flex">
                  <a-textarea
                    v-model="note"
                    placeholder="Type your text here..."
                    :auto-size="{ minRows: 3, maxRows: 5 }"
                    :max-length="500"
                  />
                  <div class="pl10" style="white-space: nowrap;margin-top: auto;">
                    {{ note.length + "/500" }}
                  </div>
                </div>
                <div
                  class="itemRemark fs-14 ff-ss fw-400"
                  style="color: #999999;"
                >
                  Add your note to let us know if you have any specific requirement on the products/orders.
                </div>
              </div>
              <div class="sellerMessageView">
                <div class="itemName fs-16 fc-333 ff-ss fw-400">
                  Email
                </div>
                <div class="itemInputView">
                  <a-form-item>
                    <a-input
                      v-model="noteEmail"
                      placeholder="Type your text here..."
                      :max-length="50"
                      type="email"
                    />
                  </a-form-item>
                </div>
                <div
                  class="itemRemark fs-14 ff-ss fw-400"
                  style="color: #999999;"
                >
                  If you’d like to confirm the actual effect once the custom items are ready, feel free to leave us an email for contact here.
                </div>
              </div>
            </div>
            <div class="typeYourTextView">
              <div class="font20 fc-333 ff-ss" style="font-weight: bold">
                Quantity <span style="color: red;">*</span>
              </div>
              <div class="pt25 w-100">
                <a-form-item>
                  <a-input-number v-model="quantity" :min="1" :max="9999" />
                </a-form-item>
              </div>
            </div>
            <div class="actionView">
              <a-button
                v-if="CurrentShop.shopType === enums.shopTypeEnum.SHOPIFY.value || CurrentShop.shopType === enums.shopTypeEnum.ETSY.value"
                :loading="syncToShopLoading"
                class="actionBtn saveBtn fs-14 ff-ss"
                style="background-color: aliceblue !important;color: rgb(49, 137, 204) !important;border-color: rgb(49, 137, 204) !important;"
                @click="syncToShop"
              >
                Sync To My Shop
              </a-button>
              <a-button
                :loading="addToCartLoading"
                class="colorprimary actionBtn addBtn fs-14 ff-ss"
                type="primary"
                @click="addToCart"
              >
                Add to cart
              </a-button>
            </div>
          </div>
        </a-form>
      </div>
    </div>
  </div>
</template>

<script>
import DiyImageBox from './components/diy_image_box'
import DiyInputBox from './components/diy_input_box'
import SkuSelector from '@/components/sku_selector'
import { productDetail, addDiyDesignProduct } from '@/api/product'
import { addDiyDesignInfo } from '@/api/design_info'
import { addToCart } from '@/api/cart'
import { mapState } from 'vuex'
import shopTypeEnum from '@/enums/shopTypeEnum'
export default {
  name: 'DiyDesign',
  components: {
    DiyImageBox,
    DiyInputBox,
    SkuSelector
  },
  props: {
    productId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      blankProductInfo: {},
      showImageList: [],
      blankProductInputBoxInfos: [],
      blankProductImageBoxInfos: [],
      skuInfos: [],
      note: '',
      noteEmail: '',
      quantity: 1,
      selectSkuId: null,
      showMoreImage: false,
      addToCartLoading: false,
      saveProductLoading: false,
      form: this.$form.createForm(this, { name: 'coordinated' }),
      enums: {
        shopTypeEnum
      },
      syncToShopLoading: false
    }
  },
  computed: {
    ...mapState({
      CurrentShop: state => state.User.merchantInfo.currentShop
    }),
    selectSku() {
      if (!this.selectSkuId) {
        return null
      }
      for (const skuInfo of this.skuInfos) {
        if (skuInfo.id === this.selectSkuId) {
          return skuInfo
        }
      }
      return null
    },
    price() {
      if (this.selectSku) {
        var discount = this.blankProductInfo.favorableDiscount || 0
        return this.selectSku.price * (1 - discount)
      } else {
        if (this.blankProductInfo.favorablePrice) {
          return this.blankProductInfo.favorablePrice
        }
        return this.blankProductInfo.price
      }
    }
  },
  mounted() {
    this.getProductDetail()
  },
  methods: {
    async getProductDetail() {
      var postData = {}
      postData.id = this.productId
      postData.blankProductInfo = true
      postData.skuInfos = true
      postData.showImageList = true
      postData.inputBoxList = true
      postData.imageBoxList = true
      const { data } = await productDetail(postData)
      this.blankProductInfo = data.blankProductInfo || {}
      this.showImageList = data.showImageList || []
      if (this.showImageList.length <= 3) {
        this.showMoreImage = true
      }
      if (data.blankProductInputBoxInfos) {
        for (const blankProductInputBoxInfo of data.blankProductInputBoxInfos) {
          blankProductInputBoxInfo.text = ''
          this.blankProductInputBoxInfos.push(blankProductInputBoxInfo)
        }
      } else {
        this.blankProductInputBoxInfos = []
      }
      if (data.blankProductImageBoxInfos) {
        for (const blankProductImageBoxInfo of data.blankProductImageBoxInfos) {
          blankProductImageBoxInfo.imageUrl = null
          this.blankProductImageBoxInfos.push(blankProductImageBoxInfo)
        }
      } else {
        this.blankProductImageBoxInfos = []
      }
      this.skuInfos = data.skuInfos || []
    },
    // 添加产品
    async saveProduct() {
      this.saveProductLoading = true
      try {
        if (!this.checkBoxInfo()) {
          return
        }
        var postData = {}
        postData.blankProductId = this.productId
        postData.note = this.note
        postData.noteEmail = this.noteEmail
        // 图像框信息
        var imageBoxInfoList = []
        for (const blankProductImageBoxInfo of this.blankProductImageBoxInfos) {
          var imageBoxInfo = {}
          imageBoxInfo.imageBoxId = blankProductImageBoxInfo.id
          imageBoxInfo.imageUrl = blankProductImageBoxInfo.imageUrl
          imageBoxInfoList.push(imageBoxInfo)
        }
        postData.imageBoxInfoList = imageBoxInfoList
        // 输入框信息
        var inputBoxInfoList = []
        for (const blankProductInputBox of this.blankProductInputBoxInfos) {
          var inputBoxInfo = {}
          inputBoxInfo.inputBoxId = blankProductInputBox.id
          inputBoxInfo.text = blankProductInputBox.text
          inputBoxInfoList.push(inputBoxInfo)
        }
        postData.inputBoxInfoList = inputBoxInfoList

        var res = await addDiyDesignProduct(postData, true)
        if (res.code === 200) {
          this.$message.success('Save product success')
          if (this.CurrentShop.shopType === shopTypeEnum.GENERAL.value || this.CurrentShop.shopType === shopTypeEnum.NOT_BIND.value) {
            this.callNewPage({
              path: '/my-product',
              query: {
                type: '2'
              }
            })
          } else {
            this.callNewPage({
              path: '/mockups',
              query: {
                customProductId: res.data,
                productId: this.productId
              }
            })
          }
        } else {
          this.$message.error(res.msg)
        }
      } finally {
        this.saveProductLoading = false
      }
    },
    // 同步产品到店铺
    async syncToShop() {
      this.syncToShopLoading = true
      try {
        var postData = {}
        postData.blankProductId = this.productId
        postData.note = ''
        postData.noteEmail = ''
        // 图像框信息
        postData.imageBoxInfoList = []
        // 输入框信息
        postData.inputBoxInfoList = []

        var res = await addDiyDesignProduct(postData, true)
        if (res.code === 200) {
          this.callNewPage({
            path: '/mockups',
            query: {
              customProductId: res.data,
              productId: this.productId
            }
          })
        } else {
          this.$message.error(res.msg)
        }
      } finally {
        this.syncToShopLoading = false
      }
    },
    // 添加到购物车
    async addToCart() {
      this.addToCartLoading = true
      try {
        this.getSku()
        if (!this.checkBoxInfo()) {
          return
        }
        if (!this.checkSku()) {
          return
        }
        if (!this.checkQuantity()) {
          return
        }

        // ----------添加设计
        var addDiyDesignInfoReq = {}
        addDiyDesignInfoReq.blankProductId = this.productId
        addDiyDesignInfoReq.note = this.note
        addDiyDesignInfoReq.noteEmail = this.noteEmail
        // 图像框信息
        var imageBoxInfoList = []
        for (const blankProductImageBoxInfo of this.blankProductImageBoxInfos) {
          var imageBoxInfo = {}
          imageBoxInfo.imageBoxId = blankProductImageBoxInfo.id
          imageBoxInfo.imageUrl = blankProductImageBoxInfo.imageUrl
          imageBoxInfoList.push(imageBoxInfo)
        }
        addDiyDesignInfoReq.imageBoxInfoList = imageBoxInfoList
        // 输入框信息
        var inputBoxInfoList = []
        for (const blankProductInputBox of this.blankProductInputBoxInfos) {
          var inputBoxInfo = {}
          inputBoxInfo.inputBoxId = blankProductInputBox.id
          inputBoxInfo.text = blankProductInputBox.text
          inputBoxInfoList.push(inputBoxInfo)
        }
        addDiyDesignInfoReq.inputBoxInfoList = inputBoxInfoList

        var addDiyDesignInfoResp = await addDiyDesignInfo(
          addDiyDesignInfoReq,
          true
        )
        if (addDiyDesignInfoResp.code !== 200) {
          this.$message.error(addDiyDesignInfoResp.msg)
          return
        }
        var designInfoId = addDiyDesignInfoResp.data

        // ---------添加购物车
        var addToCartReq = {}
        addToCartReq.blankProductId = this.productId
        addToCartReq.blankProductSkuId = this.selectSkuId
        addToCartReq.designInfoId = designInfoId
        addToCartReq.quantity = this.quantity
        var addToCartResp = await addToCart(addToCartReq, true)
        console.log(addToCartResp)
        if (addToCartResp.code !== 200) {
          this.$message.error(addToCartResp.msg)
          return
        }
        this.callNewPage('/cart')
      } finally {
        this.addToCartLoading = false
      }
    },
    // 检查输入框
    checkBoxInfo() {
      for (const blankProductImageBoxInfo of this.blankProductImageBoxInfos) {
        if (blankProductImageBoxInfo.isRequired) {
          if (!blankProductImageBoxInfo.imageUrl) {
            this.$message.error(
              `Please upload image for ${blankProductImageBoxInfo.enName}`
            )
            return false
          }
        }
      }
      for (const blankProductInputBoxInfo of this.blankProductInputBoxInfos) {
        if (blankProductInputBoxInfo.isRequired) {
          if (!blankProductInputBoxInfo.text) {
            this.$message.error(
              `Please type text for ${blankProductInputBoxInfo.enName}`
            )
            return false
          }
        }
      }
      return true
    },
    // 检查sku
    checkSku() {
      if (!this.selectSkuId) {
        this.$message.error('Please select product attributes')
        return false
      }
      return true
    },
    // 获取sku
    getSku() {
      this.selectSkuId = this.$refs['sku-selector'].getSkuId()
    },
    // 检查数量
    checkQuantity() {
      if (!this.quantity) {
        this.$message.error('Please set quantity')
        return false
      }
      return true
    },
    showGetMore() {
      this.showMoreImage = !this.showMoreImage
    },
    onSkuChange(skuId) {
      this.selectSkuId = skuId
    }
  }
}
</script>

<style lang="less" scoped>
@import "../../../assets/less/theme.less";
.diyDesignContainer {
  padding-bottom: 40px;
  /deep/ .ant-radio-inner::after {
    left: 50% !important;
    top: 50% !important;
    transform: translate(-47%, -50%) !important;
  }
  /deep/ .ant-input-number {
    background-color: #ffffff !important;
    border: 1px solid #e4e6ef !important;
  }
  /deep/ .ant-input-number-focused {
    border: 1px solid #b5b5c3 !important;
    box-shadow: none !important;
  }
  /deep/ .ant-input {
    background-color: #ffffff !important;
    border: 1px solid #e4e6ef !important;
  }
  /deep/ .ant-input:focus {
    border: 1px solid #b5b5c3 !important;
  }
  .current-selection--single /deep/ .ant-select-selection--single {
    height: 39px !important;
    line-height: 39px !important;
    background-color: #ffffff !important;
    color: #5e6278;
    border: 1px solid #e4e6ef !important;
    border-radius: 8px;
  }
  .ant-select-open /deep/ .ant-select-selection--single {
    border: 1px solid #b5b5c3 !important;
  }
    /deep/.el-dialog__wrapper {
      .commonBar(#d2d2d8, 8px, 8px);
    }
    /deep/ .el-dialog--center .el-dialog__body {
      padding: 1.5625vw 0vw 1.875vw;
    }
  width: 1400px;
  margin: 0 auto;
  overflow-y: auto;
  background-color: #f8f8f8;
  .fs-16 {
    font-size: 16px;
  }
  .fs-14 {
    font-size: 14px;
  }
  .ff-ss {
    font-family: Segoe UI-Regular, Segoe UI;
  }
  .fw-400 {
    font-weight: 400;
  }
  .fc-333 {
    color: #333333;
  }
  .h-auto {
    height: auto !important;
  }
  .backView {
    padding-top: 40px;
    padding-bottom: 24px;
    .backBtn {
      padding: 10px 24px;
      border-radius: 8px;
      font-size: 14px;
      font-family: Segoe UI-Regular, Segoe UI;
      font-weight: 400;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid transparent !important;
    }
  }
  .designContent {
    width: 100%;
    display: flex;
    .designLeftContent {
      width: 331px;
      display: flex;
      flex-direction: column;

      .customEffectsView {
        // width: 100%;
        background-color: #ffffff;
        border-radius: 12px;
        padding: 24px;
        position: relative;
        .name {
        }
        .description {
          padding-top: 24px;
        }
        .imgView {
          padding-top: 24px;
          height: 855px;
          .imgItem {
            width: 261px;
            height: 261px;
            border-radius: 12px;
            margin-bottom: 22px;
            object-fit: cover;
          }
        }
        .getMore {
          height: 24px;
          position: absolute;
          bottom: 0px;
          left: 0px;
          cursor: pointer;
          width: 100%;
          text-align: center;
        }
      }
      .giftCardView {
        // width: 100%;
        background-color: #ffffff;
        border-radius: 12px;
        padding: 24px;
        .name {
        }
        .description {
          padding-top: 24px;
        }
        .imgView {
          padding-top: 24px;
          .imgItem {
            width: 261px;
            height: 261px;
            border-radius: 12px;
            object-fit: cover;
          }
        }
      }
    }
    .designRightContent {
      width: 1069px;
      padding-left: 40px;
      .designRightTitleView {
        width: 100%;
        display: flex;
        .name {
          font-weight: bold;
        }
        .price {
          margin-left: auto;
          color: #4cbbf9;
          font-weight: bold;
        }
      }
      .designRightRemarkView {
        text-align: right;
        color: #999999;
        padding-top: 14px;
        padding-bottom: 24px;
      }
      .customEffectsItemView {
        padding: 24px;
        // width: 100%;
        background-color: #ffffff;
        border-radius: 12px;
      }
      .typeYourTextView {
        padding: 24px;
        // width: 100%;
        background-color: #ffffff;
        border-radius: 12px;
        margin-top: 40px;
      }
      .attributesView {
        padding: 24px;
        // width: 100%;
        background-color: #ffffff;
        border-radius: 12px;
        margin-top: 40px;
        .attributeItemView {
          .itemName {
            padding-top: 40px;
            padding-bottom: 14px;
          }
          .attributeSelectHasImg {
            /deep/ .el-input__prefix {
              display: flex;
              align-items: center;
            }
            /deep/ .el-input__inner {
              padding-left: 2.5vw;
            }
          }
          .attributeSelect {
          }
        }
      }
      .giftCardHandWritingView {
        padding: 24px;
        // width: 100%;
        background-color: #ffffff;
        border-radius: 12px;
        margin-top: 40px;
      }
      .giftWrapPackageView {
        padding: 24px;
        // width: 100%;
        background-color: #ffffff;
        border-radius: 12px;
        margin-top: 40px;
        .giftPackageView {
          display: flex;
          flex-wrap: wrap;
          padding-top: 40px;
          padding-bottom: 20px;
          .giftWrapPackageItemView {
            cursor: pointer;
            width: 300px;
            height: 300px;
            border-radius: 12px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin: 0 10px;
            .giftWrapPackageItemImg {
              width: 167px;
              height: 167px;
              border-radius: 4px;
            }
          }
          .giftPackageItemNoSelect {
            background-color: #ffffff;
            border: 1px dashed #dddddd;
          }
          .giftPackageItemSelect {
            background-color: #f2f6ff;
            border: 1px solid #4cbbf9;
          }
        }
      }
      .noteToSellerView {
        padding: 24px;
        // width: 100%;
        background-color: #ffffff;
        border-radius: 12px;
        margin-top: 40px;
        .sellerMessageView {
          padding-top: 24px;
          .itemName {
          }
          .itemInputView {
            padding-top: 14px;
          }
          .itemRemark {
            padding-top: 14px;
          }
        }
      }
      .actionView {
        padding-top: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        .actionBtn {
          width: 500px;
          height: 44px;
          border-radius: 8px;
        }
        .saveBtn {
        }
        .addBtn {
          margin-left: 20px;
        }
      }
    }
  }
}
</style>
