import { apiAxios } from '@/utils/request'

/** *
 * 添加DIY设计信息
 * @param data
 * @param Istoast
 * @param Send
 * @returns {Promise | Promise<unknown>}
 */
export const addDiyDesignInfo = (data, Istoast = false, Send = false) => {
  return apiAxios(
    'post',
    '/shopapi/design_info/diy/add',
    data,
    Istoast,
    Send,
    false,
    { 'Content-Type': 'application/json;charset=UTF-8' },
    true
  )
}
