<template>
  <div class="customEffectsItemContainer">
    <div class="frontTitleView">
      <span class="frontTitle fs-14 fw-400 fc-333 ff-ss">{{ title }}</span>
      <span v-if="isRequired" style="color:red;margin-left: 5px">*</span>
      <a-button
        v-if="psdFileUrl"
        style="margin-left:auto; color: #4CBBF9;border-color: #4CBBF9;"
        @click="toDownloadPsd"
      >
        <div style="display: flex;">
          <img class="dlIcon" :src="dlimg"><span
            class="frontTitleBtn"
          >Download PSD File</span>
        </div>
      </a-button>
    </div>
    <single-image-upload :image-url="imageUrl" @updateImageUrl="onImageUrlUpdate" />
    <div
      class="frontRemarkView fs-14 fw-400 ff-ss"
      style="color: #999999;"
    >
      1. After uploading your picture, we’ll produce the item as the generated mockup shows here. There might be a little tiny difference, please refer to the actual product.
      <br>
      2.Recommended image size: 4000*3000px
    </div>
  </div>
</template>

<script>
import dlimg from '@/assets/dlpsd22-21.png'
import SingleImageUpload from '@/components/single_image_upload'
export default {
  name: 'CustomEffectsItem',
  components: {
    SingleImageUpload
  },
  props: ['title', 'remark', 'psdFileUrl', 'isRequired', 'imageUrl'],
  data() {
    return {
      dlimg,
      fileList: [],
      uploadLoading: false
    }
  },
  mounted() {},
  methods: {
    toDownloadPsd() {
      window.location.href = this.psdFileUrl
    },
    onImageUrlUpdate(v) {
      this.$emit('update:imageUrl', v)
    }
  }
}
</script>

<style lang="less" scoped>
.customEffectsItemContainer {
  .padding-24 {
    padding: 24px;
  }
  .fs-14 {
    font-size: 14px;
  }
  .ff-ss {
    font-family: Segoe UI-Regular, Segoe UI;
  }
  .fw-400 {
    font-weight: 400;
  }
  .fc-333 {
    color: #333333;
  }
  .frontTitleView {
    display: flex;
    align-items: center;
    padding-top: 14px;
    padding-bottom: 24px;
    .frontTitle {
    }
    .dlIcon {
      width: 22px;
      height: 21px;
    }
    .frontTitleBtn {
      display: flex;
      align-items: center;
      padding-left: 14px;
    }
  }
  .frontContentView {
    // padding: 24px;
    background-color: #f2f6ff;
    border-radius: 10px;
    border: 1px solid #4cbbf9;
    display: flex;
    align-items: center;
    /deep/ .ant-upload {
      width: 100%;
    }
    /deep/ .ant-upload-select-text {
      span {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 24px;
      }
    }
    // /deep/ .ant-upload-list{
    //   display: none;
    // }
    .ulIcon {
      width: 50px;
      height: 50px;
    }
    .frontContentViewContent {
      padding-left: 24px;
      text-align: left;
      .pd-6px {
        padding-top: 6px;
      }
    }

    .backImgView {
      width: 72px !important;
      height: 72px !important;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: auto;
      .backImg {
        width: 72px !important;
        height: 72px !important;
        border-radius: 8px;
      }
      .removeIcon {
        position: absolute;
      }
    }
  }
  .frontRemarkView {
    padding-top: 14px;
  }
  .backTitleView {
  }
  .backContentView {
  }
  .backRemarkView {
  }
}
</style>
