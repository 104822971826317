<template>
  <div class="uploadImage">
    <template>
      <a-spin :spinning="uploadLoading">
        <a-upload
          accept=".jpg, .jpeg, .png"
          class="frontContentView cursorPointer"
          :multiple="false"
          :show-upload-list="false"
          :custom-request="submitUpload"
        >
          <div v-if="imageUrl" class="frontContentView">
            <div class="backImgView">
              <img class="backImg" :src="imageUrl">
              <img
                class="removeIcon cursorPointer"
                :src="removeIcon"
                @click="removeImage($event)"
              >
            </div>
<!--            <div class="backImgView">
              <img class="backImg" :src="ulimg">
            </div>-->
          </div>

          <template v-else>
            <img style="margin-left: 5px" class="ulIcon" :src="ulimg">
            <div class="frontContentViewContent">
              <div class="fc-333 ff-ss fs-14" style="font-weight: bold;">
                Click to upload file.
              </div>
              <div class="ff-ss fs-14 fw-400 pd-6px" style="color: #999999;">
                Choose flies from computer
              </div>
            </div>
          </template>
        </a-upload>
      </a-spin>
    </template>
  </div>
</template>

<script>
import aliOss from '@/utils/aliOss'
import { fileSts } from '@/api/oss'
import ulimg from '@/assets/ulimg50-50.png'
import removeIcon from '@/assets/removeIcon.png'

export default {
  name: 'SingleImageUpload',
  props: {
    imageUrl: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      ulimg,
      removeIcon,
      uploadLoading: false
    }
  },
  methods: {
    async submitUpload(option) {
      this.uploadLoading = true
      const currentImg = option.file
      var oss = await aliOss.oss(fileSts)
      var ossObject = aliOss.getRandomObjectName(
        oss.ossObjectNamePrefix,
        oss.domain,
        currentImg.name
      )
      // 提交到阿里云
      try {
        await oss.client.multipartUpload(ossObject.objectName, currentImg, {
          headers: { 'Cache-Control': 'max-age=360000' }
        })
        console.log('upload image success', ossObject.objectUrl)
        this.$emit('update:imageUrl', ossObject.objectUrl)
        this.$emit('updateImageUrl', ossObject.objectUrl)
        this.uploadLoading = false
      } catch (err) {
        console.log(err)
        this.uploadLoading = false
      }
    },

    toDownloadPsd() {
      window.location.href = this.psdFileUrl
    },
    removeImage(e) {
      this.$emit('update:imageUrl', null)
      this.$emit('updateImageUrl', null)
      e.stopPropagation()
    }
  }
}
</script>

<style lang="less" scoped>
.padding-24 {
  padding: 24px;
}
.fs-14 {
  font-size: 14px;
}
.ff-ss {
  font-family: Segoe UI-Regular, Segoe UI;
}
.fw-400 {
  font-weight: 400;
}
.fc-333 {
  color: #333333;
}
.frontTitleView {
  display: flex;
  align-items: center;
  padding-top: 14px;
  padding-bottom: 24px;
  .frontTitle {
  }
  .dlIcon {
    width: 22px;
    height: 21px;
  }
  .frontTitleBtn {
    display: flex;
    align-items: center;
    padding-left: 14px;
  }
}
.frontContentView {
  // padding: 24px;
  background-color: #f2f6ff;

  display: flex;
  align-items: center;
  /deep/ .ant-upload {
    width: 100%;
  }
  /deep/ .ant-upload-select-text {
    span {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 24px;
    }
  }
  // /deep/ .ant-upload-list{
  //   display: none;
  // }
  .ulIcon {
    width: 50px;
    height: 50px;
  }
  .frontContentViewContent {
    padding-left: 24px;
    text-align: left;
    .pd-6px {
      padding-top: 6px;
    }
  }

  .backImgView {
    border-radius: 10px;
    width: 72px !important;
    height: 72px !important;
    margin-left: 10px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    .backImg {
      width: 72px !important;
      height: 72px !important;
      border-radius: 8px;
    }
    .removeIcon {
      position: absolute;
    }
  }
}
.frontRemarkView {
  padding-top: 14px;
}
.backTitleView {
}
.backContentView {
}
.backRemarkView {
}

.uploadImage i {
  font-size: 32px;
  color: #999;
}

.uploadImage .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>
