<template>
  <div class="typeYourTextItemContainer">
    <div class="itemName fs-16 fc-333 ff-ss fw-400">
      {{ title }}
      <span v-if="isRequired" style="color:red">*</span>
    </div>
    <div class="itemInputView">
      <a-input
        v-model="textValue"
        placeholder="Type your text here"
        :max-length="wordNumLimit"
        @change="onTextInput"
      >
        <span slot="suffix">
          {{ text.length + `/${wordNumLimit}` }}
        </span>
      </a-input>
    </div>
    <div class="itemRemark fs-14 ff-ss fw-400" style="color: #999999;">
      Enter your preferred Name, sentences or initials(Case sensitive). Please double check your input.(Up to {{ wordNumLimit }} characters)
    </div>
    <div class="itemImgTitle fs-16 fc-333 ff-ss fw-400">Font Effect</div>
    <div v-if="text" class="itemImgView">
      <!-- <div class="carvingText" :style="`font-family:${fontFamily}`">{{ text }}</div> -->
      <div class="carvingText" :style="{ fontFamily: fontFamily2}">{{ text }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TypeYourTextItem',
  props: [
    'text',
    'title',
    'remark',
    'isRequired',
    'fontFamily',
    'wordNumLimit'
  ],
  data() {
    return {
      textValue: '',
      fontFamily2: ''
    }
  },

  watch: {
    item(newVal, oldVal) {
      console.log('newVal', newVal)
      console.log('oldVal', oldVal)
    },
    fontFamily2(newVal, oldVal) {
      this.fontFamily2 = newVal.replace(/"/g, '')
    }
  },
  created() {
    if (this.fontFamily) {
      this.fontFamily2 = this.fontFamily.replace(/"/g, '')
    }
  },
  methods: {
    handleInputChange(val) {
      console.log(val)
      this.$emit('change', { ...this.item, value: val })
    },
    onTextInput() {
      this.$emit('update:text', this.textValue)
    }
  }
}
</script>

<style lang="less" scoped>
.typeYourTextItemContainer {
  padding-top: 40px;
  .fs-14 {
    font-size: 14px;
  }
  .fs-16 {
    font-size: 16px;
  }
  .ff-ss {
    font-family: Segoe UI-Regular, Segoe UI;
  }
  .fw-400 {
    font-weight: 400;
  }
  .fc-333 {
    color: #333333;
  }
  .itemName {
  }
  .itemInputView {
    padding-top: 14px;
  }
  .itemRemark {
    padding-top: 14px;
  }
  .itemImgTitle {
    padding-top: 25px;
  }
  .itemImgView {
    margin-top: 14px;
    /deep/ img {
      width: 97px !important;
      height: 31px !important;
    }
  }
  .carvingText{
    font-size: 21px;
  }
}
</style>
